// assets/js/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/app.scss'

// cgfunding logic
import './cgfunding.js'

// start the Stimulus application
// import './bootstrap';

// loads the Bootstrap jQuery plugins
// import './bootstrap/js/dist/dropdown.js'
// import './bootstrap/js/dist/modal.js'
// import './bootstrap/js/dist/transition.js'

// loads the code syntax highlighting library
// import './highlight.js'
