// assets/js/cgfunding.js

const EDIT_ICON = '<i class="fas fa-pencil-alt"></i>'
const CANCEL_ICON = '<i class="fas fa-undo-alt"></i>'
const INVALID_ADD = 'Error: new top-level entry missing code and/or title'

const apiUrl = window.location.origin
const loadingBox = document.querySelector('div#loading')
const alertBox = document.querySelector('div#alert')
const alertMsg = document.querySelector('div#alert span')
const tableCodes = document.querySelector('table.codes')

let titleUrls = [] // hold title links when toggling a tags

const qs = (function (a) {
  if (a === '') return {}
  var b = {}

  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split('=', 2)
    if (p.length === 1) {
      b[p[0]] = ''
    } else {
      b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '))
    }
  }
  return b
})(window.location.search.substr(1).split('&'))

// rows: display
function uiToggleFields (cells, rowId) {
  var needsRefresh = false

  Array.prototype.forEach.call(cells, function (el) {
    // toggle css class
    uiToggleClass(el)

    // toggle editability
    if (el.getAttribute('contenteditable') === 'true') {
      el.setAttribute('contenteditable', 'false')
      needsRefresh = true
    } else {
      if (el.type !== 'checkbox') {
        el.setAttribute('contenteditable', 'true')
      }
    }

    // toggle expired checkbox
    if (el.getAttribute('type') === 'checkbox') {
      if (el.getAttribute('disabled')) {
        el.removeAttribute('disabled')
      } else {
        el.setAttribute('disabled', 'disabled')
      }
    }
  })

  var params = {
    'rowId': rowId
  }

  if (needsRefresh) {
    uiRefreshRows(params)
  }
}
function uiToggleClass (el) {
  var fieldClassName = 'editable-enabled'

  if (el.classList) {
    el.classList.toggle(fieldClassName)
  } else {
    var classes = el.className.split(' ')
    var fieldIndex = classes.indexOf(fieldClassName)

    if (fieldIndex >= 0) {
      classes.splice(fieldIndex, 1)
    } else {
      classes.push(fieldClassName)
    }

    el.className = classes.join(' ')
  }

  // re-link spans
  if (el.tagName === 'SPAN' && el.classList.contains('needs-relinking')) {
    el.classList.remove('needs-relinking')

    var wrapperLink = document.createElement('a')
    wrapperLink.href = titleUrls[el.id]
    wrapperLink.classList.add('title-link')
    titleUrls[el.id] = ''
    el.before(wrapperLink)
    wrapperLink.append(el)

    return
  }

  // unwrap spans
  if (el.parentNode.tagName === 'A' && el.classList.contains('title-text')) {
    el.classList.add('needs-relinking')
    titleUrls[el.id] = el.parentNode.href

    var wrapper = el.parentElement
    while (wrapper.firstChild) {
      wrapper.before(wrapper.firstChild)
    }
    wrapper.remove()
  }
}
function uiToggleRow (evt) {
  evt = evt || window.evt
  var target = evt.target || evt.srcElement
  var el = this
  var rowElemId

  // grabbing the right element regardless
  // of where the icon is clicked
  if (target.tagName === 'path') {
    target = target.parentElement.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else if (target.tagName === 'svg') {
    target = target.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else {
    rowElemId = target.parentElement.parentElement.id
  }

  var targetId = target.id
  var rowId = rowElemId.substr(rowElemId.indexOf('_') + 1)
  var row = document.getElementById(`${rowElemId}`)
  var cells = row.querySelectorAll(
    'td.pred .editable, td.code .editable, td.title .editable.text, td.depth.editable span, td.expired.editable input, td.expired.editable span')

  // toggle edit/cancel button
  if (targetId.indexOf('btnEditCancel') >= 0) {
    el.classList.toggle('editing')
    var saveButton = document.getElementById(targetId.replace('EditCancel', 'Save'))
    saveButton.classList.toggle('enabled')
    if (el.classList.contains('editing')) {
      el.innerHTML = CANCEL_ICON
    } else {
      el.innerHTML = EDIT_ICON
    }

    uiToggleFields(cells, rowId)
  }
  // toggle save button, save edits
  if (targetId.indexOf('btnSave') >= 0) {
    if (el.classList.contains('enabled')) {
      var codeNew = document.getElementById(`code_${rowId}`)
      var titleNew = document.getElementById(`title_${rowId}`)

      if (codeNew.innerText === '' && titleNew.innerText === '') {
        uiUpdateAlert('Error: new entry must have code and title', 'update')
        return false
      }

      var predNew = document.getElementById(`pred_${rowId}`)
      var depthNew = document.getElementById(`depth_${rowId}`)
      var expiredNew = document.getElementById(`expired_${rowId}`)

      var params = {
        'rowId': rowId,
        'code': codeNew.innerText,
        'title': titleNew.innerText
      }

      params.pred = predNew ? predNew.innerText : ''
      params.depth = depthNew ? depthNew.innerText : 1
      params.expired = expiredNew ? expiredNew.checked : false

      uiSaveRow(rowId, params)

      el.classList.toggle('enabled')
      codeNew.classList.remove('invalid')
      titleNew.classList.remove('invalid')
      var editButton = document.getElementById(`btnEditCancel_${rowId}`)
      if (editButton.classList.contains('editing')) {
        editButton.classList.toggle('editing')
        editButton.innerHTML = EDIT_ICON
      }

      uiToggleFields(cells, rowId)
    }
  }
}
function uiToggleAddRow (evt) {
  evt = evt || window.evt
  var target = evt.target || evt.srcElement
  var rowElemId

  // grabbing the right element regardless
  // of where the icon is clicked
  if (target.tagName === 'path') {
    target = target.parentElement.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else if (target.tagName === 'svg') {
    target = target.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else {
    rowElemId = target.parentElement.parentElement.id
  }

  var rowId = rowElemId.substr(rowElemId.indexOf('_') + 1)
  var rowAdd = document.getElementById(`rowAdd_${rowId}`)

  if (rowAdd.style.display === 'table-row') {
    rowAdd.setAttribute('opacity', '0.0')
    rowAdd.style.display = 'none'
  } else {
    rowAdd.setAttribute('opacity', '1.0')
    rowAdd.style.display = 'table-row'
    var rowAddCode = document.querySelector(`#rowAdd_${rowId} td.code.editable span`)
    rowAddCode.focus()
  }
}
function uiToggleAddRowPostCreate (rowAddId) {
  var rowAdd = document.getElementById(`rowAdd_${rowAddId}`)

  if (rowAdd) {
    if (rowAdd.style.display === 'table-row') {
      rowAdd.setAttribute('opacity', '0.0')
      rowAdd.style.display = 'none'
    } else {
      rowAdd.setAttribute('opacity', '1.0')
      rowAdd.style.display = 'table-row'
      var rowAddCode = document.querySelector(`#rowAdd_${rowAddId} td.code.editable span`)
      rowAddCode.focus()
    }
  }
}
function uiSaveRow (rowId, params) {
  apiUpdateRow(rowId, params)
}
function uiCreateTopLevelCode (evt) {
  evt = evt || window.evt

  var codeNew = document.getElementById('codeTopLevelAdd')
  var titleNew = document.getElementById('titleTopLevelAdd')

  if (codeNew.value === '' || titleNew.value === '') {
    console.error(INVALID_ADD)
    uiUpdateAlert(INVALID_ADD, 'error')
    return false
  }

  // check for dupe codes
  var xhr = new window.XMLHttpRequest()
  xhr.url = `${apiUrl}/codes/${codeNew.value}`
  xhr.open('GET', xhr.url, true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send()

  xhr.onload = function () {}
  xhr.onreadystatechange = function () {
    if (xhr.readyState === window.XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        // found code, so fail
        uiUpdateAlert(`Error: ${codeNew.value} already exists`, 'error')
        evt.preventDefault()
      } else {
        var params = {
          'code': codeNew.value,
          'qsCode': qs.code,
          'title': titleNew.value,
          'depth': 1,
          'pred': ''
        }

        if (codeNew.classList.contains('invalid')) {
          codeNew.classList.remove('invalid')
        }
        if (titleNew.classList.contains('invalid')) {
          titleNew.classList.remove('invalid')
        }

        codeNew.value = ''
        titleNew.value = ''

        apiCreateRow(params)
      }
    }
  }
}
function uiCreateRow (evt) {
  evt = evt || window.evt

  var rowId = evt.target.dataset.rowId
  var codeNew = document.getElementById(`codeAdd_${rowId}`)
  var titleNew = document.getElementById(`titleAdd_${rowId}`)
  var predNew = document.getElementById(`predAdd_${rowId}`)
  var depthNew = document.getElementById(`depthAdd_${rowId}`)

  if (codeNew.innerText === '' || titleNew.innerText === '') {
    uiUpdateAlert('Error: new entry missing code and/or title', 'error')
    return false
  } else {
    if (typeof parseInt(depthNew.innerText) !== 'number') {
      uiUpdateAlert('Error: new code depth must be numeric', 'error')
      return false
    }
  }

  // check for dupe codes
  var xhr = new window.XMLHttpRequest()
  xhr.url = `${apiUrl}/codes/${codeNew.innerText}`
  xhr.open('GET', xhr.url, true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send()

  xhr.onload = function () {}
  xhr.onreadystatechange = function () {
    if (xhr.readyState === window.XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        // found code, so fail
        uiUpdateAlert(`Error: ${codeNew.innerText} already exists`, 'error')
        evt.preventDefault()
      } else {
        var params = {
          'code': codeNew.innerText,
          'qsCode': qs.code,
          'title': titleNew.innerText,
          'depth': depthNew.innerText,
          'rowId': rowId
        }

        params.pred = predNew ? predNew.innerText : ''

        if (codeNew.classList.contains('invalid')) {
          codeNew.classList.remove('invalid')
        }
        if (titleNew.classList.contains('invalid')) {
          titleNew.classList.remove('invalid')
        }

        apiCreateRow(params)
      }
    }
  }
}
function uiDeleteRow (evt) {
  evt = evt || window.evt
  var target = evt.target || evt.srcElement
  var el = this
  var rowElemId

  if (target.tagName === 'path') {
    target = target.parentElement.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else if (target.tagName === 'svg') {
    target = target.parentElement
    rowElemId = target.parentElement.parentElement.id
  } else {
    rowElemId = target.parentElement.parentElement.id
  }

  var rowId = target.id.substr(target.id.indexOf('_') + 1)
  var rowCode = document.querySelector(`td span#code_${rowId}`).innerText
  var rowTitle = document.querySelector(`td span#title_${rowId}`).innerText
  var rowDepth = document.querySelector(`td span#depth_${rowId}`).innerText
  var trBgColor = document.getElementById(rowElemId).style.backgroundColor
  var trBgColorOrig = trBgColor
  trBgColor = '#ff8888'

  var deleteText = `Are you sure you want to DELETE the following code?

  ${rowCode} - ${rowTitle}

If so, please type 'confirm' before clicking OK.`

  var result = window.prompt(deleteText)

  var params = {
    'code': rowCode,
    'qsCode': qs.code,
    'title': rowTitle,
    'rowId': rowId,
    'depth': rowDepth
  }

  if (result) {
    if (result.toLowerCase() === 'confirm') {
      el.classList.toggle('disabled')
      el.classList.toggle('no-hover')
      apiDeleteRow(rowId, params)
    }
  } else {
    trBgColor = trBgColorOrig
  }
}
function uiRefreshRows (params) {
  if (loadingBox) {
    loadingBox.classList.toggle('show')
    tableCodes.classList.toggle('obscure')
  }

  apiRefreshRows(params)
}

// rows: modification api
function apiRefreshRows (params) {
  var xhr = new window.XMLHttpRequest()
  var rowId = params.rowId
  var code = params.qsCode || params.code
  var depth = params.depth

  if (code) {
    if (depth == 1 || depth === '') {
      xhr.url = `${apiUrl}/rows`
    } else {
      xhr.url = `${apiUrl}/rows/?code=${code}`
    }
  } else if (rowId) {
    xhr.url = `${apiUrl}/rows/${params.rowId}/json`
  } else {
    xhr.url = `${apiUrl}/rows`
  }
  xhr.open('GET', xhr.url, true)
  xhr.send(JSON.stringify(params))

  xhr.onreadystatechange = function () {
    if (_xhrDone(xhr)) {
      if (code) {
        // row add or delete = refresh values for all rows
        tableCodes.innerHTML = xhr.response
      } else if (rowId) {
        // row update = refresh values for row
        JSON.parse(xhr.response, function (key, value) {
          switch (key) {
          case 'pred':
            var predPrev = document.getElementById(`pred_${rowId}`)
            if (predPrev) predPrev.innerText = value
            break
          case 'code':
            var codePrev = document.getElementById(`code_${rowId}`)
            if (codePrev) codePrev.innerText = value
            break
          case 'title':
            var titlePrev = document.getElementById(`title_${rowId}`)
            if (titlePrev) titlePrev.innerText = value
            break
          case 'depth':
            var depthPrev = document.getElementById(`depth_${rowId}`)
            if (depthPrev) depthPrev.innerText = value
            break
          case 'expired':
            var expPrev = document.getElementById(`expired_${rowId}`)
            if (expPrev) expPrev.checked = value
            break
          }
        })
      }

      if (loadingBox) {
        tableCodes.classList.toggle('obscure')
        loadingBox.classList.toggle('show')
      }

      // re-apply event handlers after codes refresh
      _applyEventHandlers()
    }
  }
}
function apiUpdateRow (rowId, params) {
  var xhr = new window.XMLHttpRequest()
  xhr.url = `${apiUrl}/rows/${rowId}`
  xhr.open('PUT', xhr.url, true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send(JSON.stringify(params))

  xhr.onload = function () {}
  xhr.onreadystatechange = function () {
    if (_xhrDone(xhr)) {
      uiUpdateAlert(`${params.code}:"${params.title}" updated`, 'update')
    }
  }
}
function apiCreateRow (params) {
  var xhr = new window.XMLHttpRequest()
  xhr.url = `${apiUrl}/rows/create`
  xhr.open('POST', xhr.url, true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send(JSON.stringify(params))

  xhr.onreadystatechange = function () {
    if (_xhrDone(xhr)) {
      uiUpdateAlert(`${params.code}:"${params.title}" created at a depth of ${params.depth}`, 'create')
      uiToggleAddRowPostCreate(params.rowId)
      uiRefreshRows(params)
    }
  }
}
function apiDeleteRow (rowId, params) {
  var xhr = new window.XMLHttpRequest()
  xhr.url = `${apiUrl}/rows/delete/${rowId}`
  xhr.open('DELETE', xhr.url, true)
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send()

  xhr.onload = function () {}
  xhr.onreadystatechange = function () {
    if (_xhrDone(xhr)) {
      uiUpdateAlert(`${params.code}:"${params.title}" deleted`, 'delete')
      uiRefreshRows(params)
    }
  }
}

// validation
/*
function uiCodeValidation (evt) {
  evt = evt || window.evt

  var maxLength = 7

  var backKey = 8
  var tabKey = 9
  var delKey = 46
  var arrow1 = 37
  var arrow2 = 38
  var arrow3 = 39
  var arrow4 = 40

  var acceptedKeysPreLimit = [backKey, delKey, tabKey, arrow1, arrow2, arrow3, arrow4, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105]
  var acceptedKeysPostLimit = [backKey, delKey, tabKey, arrow1, arrow2, arrow3, arrow4]

  // pre-max limit
  if (!acceptedKeysPreLimit.includes(evt.keyCode)) {
    evt.preventDefault()
  }
  // post-max limit
  if (evt.target.innerText.length >= maxLength) {
    if (!acceptedKeysPostLimit.includes(evt.keyCode)) {
      evt.preventDefault()
    }
  }
}
*/

// alerts: display
function uiUpdateAlert (msg, type) {
  alertMsg.innerHTML = `<strong>${msg}</strong>`

  switch (type) {
  case 'create':
    alertBox.classList.add('alert-create')
    alertBox.classList.remove('alert-info')
    alertBox.classList.remove('alert-delete')
    alertBox.classList.remove('alert-error')
    alertBox.classList.remove('alert-update')
    break
  case 'update':
    alertBox.classList.add('alert-update')
    alertBox.classList.remove('alert-info')
    alertBox.classList.remove('alert-create')
    alertBox.classList.remove('alert-delete')
    alertBox.classList.remove('alert-error')
    break
  case 'delete':
    alertBox.classList.add('alert-delete')
    alertBox.classList.remove('alert-info')
    alertBox.classList.remove('alert-create')
    alertBox.classList.remove('alert-error')
    alertBox.classList.remove('alert-update')
    break
  case 'error':
    alertBox.classList.add('alert-error')
    alertBox.classList.remove('alert-info')
    alertBox.classList.remove('alert-create')
    alertBox.classList.remove('alert-delete')
    alertBox.classList.remove('alert-update')
    break
  default:
    alertBox.classList.add('alert-info')
    alertBox.classList.remove('alert-create')
    alertBox.classList.remove('alert-delete')
    alertBox.classList.remove('alert-update')
    alertBox.classList.remove('alert-error')
    break
  }

  uiShowAlert()
}
function uiShowAlert () {
  var alertDiv = document.getElementById('alert')
  if (alertDiv) {
    alertDiv.setAttribute('opacity', '1.0')
    alertDiv.style.display = 'block'
  }
}
function uiHideAlert () {
  var alertDiv = document.getElementById('alert')
  if (alertDiv) {
    alertDiv.setAttribute('opacity', '0.0')
    alertDiv.style.display = 'none'
  }
}

function _xhrDone (xhr) {
  return xhr.readyState === window.XMLHttpRequest.DONE && xhr.status === 200
}
function _applyEventHandlers () {
  var i = 0

  var addToggleButtons = document.querySelectorAll('button.add-toggle')
  if (addToggleButtons.length > 0) {
    for (i = 0; i < addToggleButtons.length; i++) {
      addToggleButtons[i].addEventListener('click', uiToggleAddRow)
    }
  }

  var addButtons = document.querySelectorAll('button.add')
  if (addButtons.length > 0) {
    for (i = 0; i < addButtons.length; i++) {
      addButtons[i].addEventListener('click', uiCreateRow)
    }
  }
  var deleteButtons = document.querySelectorAll('button.delete')
  if (deleteButtons.length > 0) {
    for (i = 0; i < deleteButtons.length; i++) {
      deleteButtons[i].addEventListener('click', uiDeleteRow)
    }
  }
  var editButtons = document.querySelectorAll('button.edit')
  if (editButtons.length > 0) {
    for (i = 0; i < editButtons.length; i++) {
      editButtons[i].addEventListener('click', uiToggleRow)
    }
  }
  var saveButtons = document.querySelectorAll('button.save')
  if (saveButtons.length > 0) {
    for (i = 0; i < saveButtons.length; i++) {
      saveButtons[i].addEventListener('click', uiToggleRow)
    }
  }
  var refreshButton = document.querySelector('button#refreshRows')
  if (refreshButton) {
    refreshButton.addEventListener('click', uiRefreshRows)
  }
  var alertCloseButton = document.querySelector('#alert')
  if (alertCloseButton) {
    alertCloseButton.addEventListener('click', uiHideAlert)
  }
  /* comment out validation for now until it can be refactored
  var codeInputs = document.querySelectorAll('.code span')
  if (codeInputs.length > 0) {
    for (i = 0; i < codeInputs.length; i++) {
      codeInputs[i].addEventListener('keydown', uiCodeValidation)
    }
  }
  */

  var addTopLevelCode = document.querySelector('button#btnTopLevelAdd')
  if (addTopLevelCode) {
    addTopLevelCode.addEventListener('click', uiCreateTopLevelCode)
  }
}

window.onload = function () {
  _applyEventHandlers()
}
